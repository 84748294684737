<template>
    <div class="rocket_float">
        <div class="rocket_float_btn" v-show="!dialogShow&&$store.state.isLoginStatu">
            <div class="icon_container" @click="dialogShow = true">
                <img class="rocket_glare" src="@/assets/images/rocket_active/rocket_glare.png" alt="">
                <img class="rocket_model" src="@/assets/images/rocket_active/rocket_model.png" alt="">
            </div>
        </div>
        <transition name="fade">
        <div class="mask" v-if="dialogShow&&$store.state.isLoginStatu" @click="dialogShow = false">
            <div class="pop_container">
                <img class="close_btn" src="@/assets/images/rocket_active/close_btn.png"  alt="">
                <img class="rocket_glare_big" src="@/assets/images/rocket_active/rocket_glare.png" alt="">
                <img class="rocket_model_big" src="@/assets/images/rocket_active/rocket_model.png" alt="">
            </div>
            <button type="button" class="jump_page_btn" @click.stop="openActive">
                {{ $t("您可以获得") }} <span class="amount-font">10000BRL</span>
            </button>
        </div>
        </transition>

    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogShow: false
        };
    },
    methods:{
        openActive(){
            this.$router.push({path:"/rocket-activity"})
        }
    }
};
</script>

<style lang="less" scoped>

.rocket_float{  
    @media(min-width:768px) {
    display: none;
    }
}

.rocket_float_btn {
    position: fixed;
    bottom: 290px;
    right: 12px;
    z-index: 99;
    width: 76px;
    height: 76px;
}

.icon_container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        object-fit: cover;
    }
}

.rocket_glare{
    animation: 3s glare infinite;
    animation-delay: 0.4s;
}

@keyframes glare {
    0% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }

    50% {
        transform: scale(.95) translateY(-5%);
        opacity: .8;
    }

    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.rocket_model {
    position: absolute;
    width: 36px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    animation: 3s rocket-model infinite;
    animation-delay: 0.4s;
}

@keyframes rocket-model {
    0% {
        transform: translate(-50%, -45%);
    }

    50% {
        transform: translate(-50%, -60%);
    }

    100% {
        transform: translate(-50%, -45%);
    }
}

.mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.rocket_glare_big{
    animation: 3s glare2 infinite;
    animation-delay: 0.4s;
}

@keyframes glare2 {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(.9);
        opacity: .8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.rocket_model_big {
    position: absolute;
    width: 126px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    object-fit: cover;
}
.pop_container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jump_page_btn{
    background: url(../assets/images/rocket_active/rocket_active_btn.png);
    background-size: 100% 100%;
    width: 100%;
    max-width: 296px;
    padding: 18px;
    font-size: 14px;
    font-weight: 500;
    text-shadow: -1px 2px 2px rgba(6, 14, 4, 0.89);
    transform: translateY(-40px);
}

.amount-font{
    color:rgba(254, 226, 97, 1);

}
.close_btn{
    position: absolute;
    right: 15%;
    top: 0;
    width: 20px;
    z-index: 2;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>