import { render, staticRenderFns } from "./downPop.vue?vue&type=template&id=a47de32a&scoped=true&"
import script from "./downPop.vue?vue&type=script&lang=js&"
export * from "./downPop.vue?vue&type=script&lang=js&"
import style0 from "./downPop.vue?vue&type=style&index=0&id=a47de32a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a47de32a",
  null
  
)

export default component.exports