<template>
    <transition name="fade">
    <div class="down_pop_mask" v-if="show" @click="closePop">
        <div class="pop_container">
            <div class="img_wrap">
                <img class="down_logo" src="@/assets/images/down_logo.png" alt="">
                <img class="down_img" src="@/assets/images/down_img.png" alt="">
                <div class="ios_wrap"><i class="iconfont icon-ios ios_icon"></i> IOS</div>
                <div class="pop_title_container">
                    <div class="pop_title">
                        <span>{{ $t("添加到主屏幕") }}</span>
                    </div>

                </div>
            </div>
            <div class="content_bg">
                <div class="content_container">
                    <div class="step_item">
                        <div class="step_index">1</div>
                        <div class="step_content">
                            <p>{{ $t("运行Safari并打开此网站") }}</p>
                        </div>
                    </div>
                    <div class="step_item">
                        <div class="step_index">2</div>
                        <div class="step_content">
                            <p>{{ $t("分享按钮") }}</p>
                            <div class="demonstrate">
                                <img class="show_icon_smll" src="@/assets/images/show_icon_arrow.png" alt="">
                                <img class="show_icon_smll" src="@/assets/images/show_icon_arrow.png" alt="">
                                <div class="icon_halo">
                                    <img class="show_icon icon_halo" src="@/assets/images/show_icon_share.png" alt="">
                                </div>
                                <img class="show_icon" src="@/assets/images/show_icon_bookmark.png" alt="">
                                <img class="show_icon" src="@/assets/images/show_icon_copy.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="step_item">
                        <div class="step_index">3</div>
                        <div class="step_content">
                            <p>{{$t('选择"添加到主屏幕"')}}</p>
                            <div class="demonstrate pr-3">
                                <span>{{ $t('添加到主屏幕') }}</span>
                                <div class="icon_halo">
                                    <img class="show_icon icon_halo" src="@/assets/images/show_icon_share.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step_item">
                        <div class="step_index">4</div>
                        <div class="step_content">
                            <p>{{ $t("点击添加") }}</p>
                            <div class="demonstrate pr-5">
                                <span>{{$t("取消添加到主屏幕")}} </span>
                                <div class="icon_halo txt_halo">
                                    <span class="step_add_btn">{{$t('增添')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    methods:{
        closePop(){
            this.$emit('closeIosPop',false)
        }
    }
}

</script>

<style lang="less" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.down_pop_mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    padding: 16px;
}

.pop_container {
    position: relative;
    width: 100%;
    max-width: 326px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 310px;
    // justify-content: center;
    position: relative;
    padding: 0 20px;

}

.down_logo {
    max-width: 104px;
    margin-left: 16px;
    transform: translateY(-12px);
}

.down_img {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 160px;
    z-index: 2;
}

.ios_wrap {
    position: absolute;
    bottom: 64px;
    left: 42px;
    font-size: 24px;
}

.ios_icon {
    font-size: 28px;
}

.pop_title_container {
    position: relative;
    margin-top: 13px;
    width: 100%;
    background: #299F50;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    padding: 10px 120px 10px 20px;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    letter-spacing: 1px;
    box-shadow: inset 0 0 1px #27eb67;
    min-height: 62px;
    display: flex;
    align-items: center;
}

.pop_title_container::before {
    content: '';
    position: absolute;
    top: -47px;
    left: 20%;
    width: 70px;
    height: 46px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 0 28px 30px RGBA(39, 235, 103, 1);
    letter-spacing: 1px
}

.pop_title {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px 120px 10px 20px;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 0px 3px 0px rgba(15, 24, 2, 0.76);
}

.content_bg {
    width: 100%;
    background: linear-gradient(0deg, #109665, #53E2A6);
    padding: 14px;
    border-radius: 20px;
}

.content_container {
    width: 100%;
    height: 100%;
    background: #D2F6D5;
    border-radius: 18px;
    padding: 12px;
}

.step_item {
    display: flex;
    align-items: stretch;
    font-size: 12px;
    background: linear-gradient(86deg, #C1FDC9, #90F9C4, #57F5A1);
    box-shadow: 0px 3px 10px 0px rgba(15, 24, 2, 0.14);
    border-radius: 10px;
    margin-bottom: 16px;
}

.step_item:last-child {
    margin-bottom: 0;
}

.step_index {
    width: 44px;
    background: linear-gradient(181deg, #2FB383, #0B643E);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 4px;
}

.step_content {
    padding: 10px 8px;
    color: #074C87;
    text-align: center;
    width: 100%;
}

.demonstrate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #7EB77E;
    padding: 8px 10px;
    border-radius: 8px;
    font-size: 10px;
    margin-top: 6px;
}

.show_icon_smll {
    height: 12px;
}

.show_icon {
    width: 16px;
}

.icon_halo {
    position: relative;
    z-index: 2;
}

.icon_halo::before {
    content: '';
    position: absolute;
    background: #00FF78;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.icon_halo::after {
    content: '';
    position: absolute;
    background: #CCF6CC;
    opacity: .4;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.step_add_btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 10px;
    white-space: nowrap;
}

.pr-3 {
    padding-right: 12px;
}

.pr-5 {
    padding-right: 20px;
}</style>