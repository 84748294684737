<template>
  <div
    class="app"
    :class="`${openedleft ? 'opened-left-panel' : ''}${openedcontests ? ' opened-contests-panel' : ''}`"
  >
    <Head :flagShow="openedleft" :isMobile="isMobileFlag" @menuClick="menuClick" />

    <div class="wrapper" id="home-wapper">
      <div class="wrapper__inner">
        <div class="container">
          <el-skeleton :rows="1" :count="1" animated :throttle="500" v-if="!skeletonShow">
            <template slot="template">
              <div class="el-m-b-15 b-pics">
                <div class="b-pic" v-for="item in 3"><el-skeleton-item variant="image" style="width:100%;height:210px;border-radius:10px;" /></div>
              </div>
              <div class="el-m-b-15"><el-skeleton-item variant="h1" style="height:80px;" /></div>
              <div class="el-m-b-15" style="max-width:100%"><el-skeleton-item variant="h2"  /></div>
              <div class="el-m-b-15" style="max-width:100px"><el-skeleton-item variant="text"  /></div>
              <div class="games__row">
                <div class="col" v-for="item in 12">
                  <div class="game-preview" style="display:flex; justify-content: center;align-items: center; height:200px;">
                    <el-skeleton-item variant="image" style="width:100%;height:100%" />
                  </div>
                </div>
              </div>
            </template>
          </el-skeleton>
          <template v-if="skeletonShow">
            <!-- banner -->
            <banner class="banner-box"></banner>
            
            <merryGoRound class="merry-GoRound-box"></merryGoRound>
            
            <!-- 大赢家 -->
            <!-- <bigWinner :list="high_wins"></bigWinner> -->
            <!-- jackpot -->
            <jackpot v-if="$t('jackpot_hide')!='hide'" />
            <div class="lobby__tabs-box">
              <div class="overflow-auto">
                <div class="lobby-tabs lobby__tabs">
                  <div
                    class="tab"
                    v-for="(item,index) in gameClassification"
                    :class="index == tabscur?'active':''"
                    :key="`gameClassification${index}`"
                    @click="gameTabChange(index,item.id)"
                  >
                  
                    <span class="tab__icon">
                      <img width="20" height="20" v-lazy="item.icon" />
                      <div class="tab-hot" v-if="item.id == 24">
                    <div class="circle">
                      <svgIcon icon="icon-play" class="reflection"></svgIcon>
                    </div>
                  </div>
                    </span>
                    <span class="tab__title">{{ $t(item.name) }}</span>
                    <!-- <span
                      class="tab__badge"
                      v-if="index == tabscur && item.game_count > 0"
                    >{{item.game_count}}</span> -->
                  </div>
                  <div class="search" style="display:none;">
                    <el-input
                      :placeholder="$t('搜索游戏')"
                      size="small"
                      v-model="searchInput"
                      @focus="searchfocus"
                      @blur="searchblur"
                      @input="searchchange"
                    >
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <div class="dropdown-search" v-if="searchShow">
                      <div class="dropdown-search__head" v-if="searchEmpty">
                        <div class="search-head">
                          <span class="search-head__head-info">{{$t('搜索至少需要2个字符')}}</span>
                        </div>
                      </div>
                      <div class="dropdown-search__body" v-else>
                        <div class="search-scroll">
                          <div class="search-scroll__inner">
                            <div
                              class="col"
                              v-for="(item,index) in indexSearchGameList"
                              :key="`indexSearchGameList${index}`"
                            >
                              <gameItem :item="item" :lazy="false"></gameItem>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            <div class="all-games" >
              <div class="lobby">
                <!-- pc filter -->
                <div class="games" v-if="tabscur > 0">
                  <none 
                  v-if="gameIndexListHot.length==0" 
                  style="margin:0"
                  v-loading="loading"
                    :element-loading-text="$t('加载中')"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                  ></none>
                  <div class="games__row"
                    v-loading="loading"
                    :element-loading-text="$t('加载中')"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    v-if="!loading || gameIndexListHot.length > 0">
                    <div class="col" v-for="(item,index) in gameIndexListHot" :key="index">
                      <gameItem :item="item" :lazy="false" @cancelFavorite="cancelFavorite(index)"></gameItem>
                    </div>
                  </div>
                  <div v-if="gameIndexListHot.length >= limit" class="games__footer">
                    <button :class="loading ? 'button_loading' : ''" @click="loadMore" type="button" class="button button_lg button_grey1 button_center">
                      <span class="button__inner">
                        <span class="button__text">{{ $t('Load more') }}</span></span>
                    </button>
                  </div>
                </div>
                
                <div class="game-main" v-if="gameTabId <= 0"
                style="min-height: 200px"
                v-loading="loading"
                :element-loading-text="$t('加载中')"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <div v-for="(item) in gameIndexListData"
                      :key="item.id">
                    <div class="category" v-if="item.type==='platform_list'">
                      <div class="category__head">
                        <div class="category__title">
                          <img :src="item.icon">
                          <span>{{ $t(item.name) }}</span>
                        </div>
                        <div class="category__head-buttons">
                          <button
                            type="button"
                            class="button button_md button_grey1 button_center button_only-icon button_only-leftd"
                            aria-label="Previous"
                          >
                            <svgIcon
                              icon="icon-arrow-left-small"
                              class="link__icon"
                              widthName="24"
                              heightName="24"
                            ></svgIcon>
                          </button>
                          <button
                            type="button"
                            class="button button_md button_grey1 button_center button_only-icon button_only-rightd"
                            aria-label="Next"
                          >
                            <svgIcon
                              icon="icon-arrow-right-small"
                              class="link__icon"
                              widthName="24"
                              heightName="24"
                            ></svgIcon>
                          </button>
                        </div>
                      </div>
                      <div class="category__body" >
                        <swiper class="swiper gallery-games" :options="swiperGamesD" ref="swiperThumbse">
                          <swiper-slide v-for="(itemChild,index) in item.list" :key="index">
                            <div class="provider" @click="toProvider(itemChild)">
                              <div class="logotype">
                                <img v-lazy="itemChild.icon" class="lazy-picture__image" style="border-radius: 0px;"/>
                              </div>
                              <div class="provider-name">
                                <img src="@/assets/images/game-samll-icon.png" alt="">
                                <span>{{ itemChild.game_number }}</span>
                              </div>
                            </div>
                          </swiper-slide>
                        </swiper>
                      </div>
                    </div>
                    <div class="category" v-else>
                      <div class="category__head category__title_button" v-if="item.list.length > 0">
                        <div class="category__title">
                          <img :src="item.icon">
                          <span>{{ $t(item.name) }}</span>
                        </div>
                        <div class="category__head-buttons" v-if="item.href && item.href!=='#'">
                          <div @click="$router.push(item.href)" class="button button_md button_grey1 button_center title-button">
                            <span class="button__inner">
                              <span class="button__text">{{$t('全部查看')}}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="category__body">
                        <div class="games__row">
                          <div class="col" v-for="(item1,index) in item.list" :key="index">
                            <gameItem :item="item1" :lazy="false"></gameItem>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </template>
        </div>
      </div>
      <Foot />
    </div>
    <asidebar :flagShow="openedleft" @menuClick="menuClick"></asidebar>
    <tabbar :flagShow="openedleft" @menuClick="menuClick" @contestsShow="contestsShow"></tabbar>
    <div
      class="app-overlay"
      v-if="isMobileFlag"
      :class="openedleft?'visible':''"
      @click="menuClick"
    ></div>
    <!-- 公告 -->
    <welcome :welShow="homeWelcome"></welcome>
    <notice></notice>
    <gameDialog ref="gameDialog"></gameDialog>
	  <!-- <LoginModal ref="loadinModal"></LoginModal> -->
	  <!-- 下载提示 -->
	  <DownloadTips @openIosDownPop="openIosDownPop"></DownloadTips>

    <!-- 顶部提示 -->
    <headerTip></headerTip>
    <gift></gift>
    <rocketPop />


    <!-- <floatWindow  @openIosDownPop="openIosDownPop"/> -->
    <downPop :show="showIosPop" @closeIosPop="closeIosPop" />
    <gameInfoPop />
    <doldenEgg></doldenEgg>
  </div>
</template>
<script>
import CacheMixin from '@/common/CacheMixin'
import Head from "@/components/Head.vue";
import LoginModal from "@/components/LoginModal.vue";
import DownloadTips from "@/components/DownloadTips.vue";
import Foot from "@/components/Foot.vue";
import asidebar from "@/components/aside.vue";
import svgIcon from "@/components/svg.vue";
import tabbar from "@/components/tabbar.vue";
import welcome from "@/components/welcome.vue";
import turntable from "@/components/turntable.vue";
import bigWinner from "./components/bigWinner.vue";
import activity from "./components/activity.vue";
import gameDialog from "@/components/gameDialog.vue";
import activitySwiper from "./components/activitySwiper.vue";
import jackpot from "./components/jackpot.vue";
import gameItem from '@/components/games/gameItem.vue'
import notice from '@/components/notice.vue'
import headerTip from '@/components/headerTip.vue'
import merryGoRound from '@/components/merryGoRound.vue'
import banner from './components/banner.vue'
import gift from '@/components/gift.vue'

import rocketPop from '@/components/rocketPop.vue'
import floatWindow from '@/components/floatWindow.vue'
import gameInfoPop from '@/components/gameInfoPop.vue'
import downPop from "@/components/downPop.vue"
import doldenEgg from "@/components/doldenEgg.vue"
import {
  game_layout_api,
  game_get_type_api,
  all_game_order_api,
  game_high_order_api,
  user_game_order_api,
  game_list_api,
  game_game_list_api,
} from "@/api/cybSports";
export default {
  name: 'Index',
  mixins: [CacheMixin],
  components: {
    merryGoRound,
    Head,
    Foot,
    asidebar,
    svgIcon,
    tabbar,
    welcome,
    turntable,
    bigWinner,
    activity,
    gameDialog,
    DownloadTips,
    activitySwiper,
    jackpot,
	  LoginModal,
    gameItem,
    notice,
    banner,
    headerTip,
    gift,
    floatWindow,
    gameInfoPop,
    downPop,
    rocketPop,
    doldenEgg
  },
  data() {
    return {
      showIosPop:false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      homeWelcome: false,
      tabscur: 0,
      // mobile 筛选
      gameTabicon: require("@/assets/images/icon-lobby.png"),
      gameTabValue: this.$t("大厅"),
      stakingthumbs: [],
      swiperOptionThumbs: {
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".splide__arrow--next",
          prevEl: ".splide__arrow--prev"
        },
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
        breakpoints: {
          640: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          1200: {
            slidesPerView: 3
          }
        }
      },
      swiperGamesA: {
        navigation: {
          nextEl: ".button_only-righta",
          prevEl: ".button_only-lefta"
        },
        slidesPerView: 6,
        spaceBetween: 16,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          750: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 6
          }
        }
      },
      swiperGamesB: {
        navigation: {
          nextEl: ".button_only-rightb",
          prevEl: ".button_only-leftb"
        },
        slidesPerView: 6,
        spaceBetween: 16,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          750: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 6
          }
        }
      },
      swiperGamesC: {
        navigation: {
          nextEl: ".button_only-rightc",
          prevEl: ".button_only-leftc"
        },
        slidesPerView: 6,
        spaceBetween: 30,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          750: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 20
          }
        }
      },
      swiperGamesD: {
        navigation: {
          nextEl: ".button_only-rightd",
          prevEl: ".button_only-leftd"
        },
        slidesPerView: 5,
        spaceBetween: 16,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          750: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 10
          }
        }
      },
      high_wins: [],
      high_bets: [],
      activeName: "first",
      loginReg: true, //未登录
      gameIndexListHot: [], //游戏首页热门
      gameIndexListData: [], //游戏首页
      gameClassification: [], // 游戏分类
      gameIndexOrderList: [], //首页游戏订单
      gameIndexOrderListEmpty: 0, //首页游戏订单缺省
      gameOrderListPage: 1,
      userGameOrderList: [], //首页游戏用户订单
      userGameOrderListEmpty: 0, //首页游戏用户订单缺省
      searchShow: false,
      searchEmpty: true,
      searchInput: "",
      gameTabId: 0,
      page: 1,
      limit: 24,
      loading: false,
      isFilterGame: true,
      indexSearchGameList: [], //搜索游戏列表
      skeletonShow:false,
      giftPic:require('@/assets/images/turntable/liwu.jpeg'),
    };
  },
  methods: {
    openIosDownPop(value){
      this.showIosPop = value
    },
    closeIosPop(value){
				this.showIosPop = value
			},
    initEnv(){
      const env = ("standalone" in window.navigator) && window.navigator.standalone ? "app" : this.$route.query.env
			if(env){
				this.$store.commit("setEnv",env)
			}
		},
    async toProvider(item){
      this.$router.push(`/all-game?ptid=${item.tc_id}`)
    },
    searchfocus() {
      this.searchShow = true;
    },
    searchblur() {
      if (this.searchInput == "") {
        this.searchShow = false;
        this.searchInput = "";
        this.indexSearchGameList = [];
        this.searchEmpty = true;
      }
    },
    async searchchange(e) {
      if (e.length > 1) {
        let param = {
          page: 1,
          limit: 18,
          keywords: e
        };
        await game_list_api(param).then(res => {
          if (res && res.data.code == 1) {
            this.indexSearchGameList = res.data.data;
            if (this.indexSearchGameList.length > 0) {
              this.searchEmpty = false;
            } else {
              this.searchEmpty = true;
            }
          }
        });
      }
    },
    async getIndexGameList() {
      const {data:res} = await game_layout_api({ nlimit: 12 })
      if (res && res.code == 1) {
        this.gameIndexListData = res.data;
        this.skeletonShow = true
      }
    },
    async getGameListById() {
      this.loading = true;
      await game_game_list_api(this.page, this.limit, this.gameTabId).then(response => {
        let res = response.data;
        this.loading = false;
        if (res && res.code == 1) {
          if (this.page == 1) {
            this.gameIndexListHot = res.data;
            // console.log(this.gameIndexListHot)
          } else {
            for (const key in res.data) {
              if (Object.hasOwnProperty.call(res.data, key)) {
                const element = res.data[key];
                this.gameIndexListHot.push(element)
              }
            }
          }
        }
      });
    },
    // 获取游戏分类
    async getGameGetType() {
      await game_get_type_api().then(res => {
        if (res && res.data.code == 1) {
          this.gameClassification = res.data.data;
          if (this.gameClassification.length > 0) {
            this.gameClassification.push({
              icon: require("@/assets/images/sc.png"),
              id: 'collect_id',
              name: '我的收藏',
              sort: 0
            });
            this.gameClassification.unshift({
              icon: require("@/assets/images/icon-lobby.png"),
              id: -1,
              name: this.$t("大厅"),
              sort: 0
            });
          }
        }
      });
    },
    // 获取首页游戏订单
    getGameIndexOrderList() {
      all_game_order_api(this.gameOrderListPage).then(res => {
        if (res && res.data.code == 1) {
          this.gameIndexOrderList = res.data.data;
          if (this.gameIndexOrderList.length > 0) {
            this.gameIndexOrderListEmpty = 1;
          } else {
            this.gameIndexOrderListEmpty = 2;
          }
        }
      });
    },
    getGameHighOrder() {
      game_high_order_api().then(res => {
        if (res && res.data.code == 1) {
          this.high_bets = res.data.data.high_bet;
          this.high_wins = res.data.data.high_win;
        }
      });
    },
    // 获取我的投注
    getUserGameOrder() {
      user_game_order_api().then(res => {
        if (res && res.data.code == 1) {
          this.userGameOrderList = res.data.data;
          if (this.userGameOrderList.length > 0) {
            this.userGameOrderListEmpty = 1;
          } else {
            this.userGameOrderListEmpty = 2;
          }
        }
      });
    },
    menuClick() {
      this.openedleft = !this.openedleft;
    },
    contestsClick() {
      this.openedcontests = !this.openedcontests;
    },
    contestsShow() {
      this.openedcontests = true;
    },
    gameTabChange(index, id) {
      if(id=="collect_id"){
        // 判断登录
        if (!this.$store.state.isLoginStatu) {
          this.showLoginReg(1);
        }
      }
      this.tabscur = index;
      this.gameTabId = id;
      if (id > 0 || id=="collect_id") {
        this.page = 1;
        // this.gameIndexListHot = [];
        this.getGameListById();
      }
    },
    loadMore() {
      this.page += 1;
      this.getGameListById();
    },

    /**
     * 取消收藏，更新列表
     * @param {Number} index 索引
     * */ 
    cancelFavorite(index){
      if(this.gameTabId=="collect_id"){
        this.gameIndexListHot.splice(index,1)
        this.$forceUpdate()
      }
    },
    tabChange(e) {
      for (let i in this.gameClassification) {
        if (this.gameClassification[i].name == e) {
          this.gameTabValue = this.gameClassification[i].name;
          this.gameTabicon = this.gameClassification[i].icon;
          this.gameTabId = this.gameClassification[i].id;
          this.tabscur = this.gameClassification[i].id;
          if (this.gameClassification[i].id  > 0 || this.gameClassification[i].id=="collect_id") {
            if(this.gameClassification[i].id=="collect_id"){
              // 判断登录
              if (!this.$store.state.isLoginStatu) {
                this.showLoginReg(1);
              }
            }
            this.page = 1;
            this.gameIndexListHot = [];
            this.getGameListById();
          }
        }
      }
    },
    notice() {
      this.$notify({
        customClass: "noticeClass",
        dangerouslyUseHTMLString: true,
        duration: "5000",
        message:
          '<div class="notice-bar">' +
          '<div class="bar-ico">⚔️</div>' +
          '<div class="flex_bd">' +
          '<div class="notice-tit">Pinned message</div>' +
          '<div class="notice-desc">Watch your bets history for all cryptocurrencies.</div>' +
          "</div>" +
          "</div>"
      });
    },
    orderTab() {
      if (this.activeName == 'first') {
        this.getGameIndexOrderList();
      } else if (this.activeName == 'second') {
        this.getUserGameOrder();
      } else if (this.activeName == 'third' || this.activeName == 'fourth') {
        this.getGameHighOrder();
      }
    },
    viewMore(type){
      //跳转pg游戏
      if(type=="top"){
        this.$router.push("/all-game?tab=all&ptid=19")
      }else if(type=="new"){
      // 跳转真人
        this.$router.push("/all-game?tab=24")
      }else{
        this.$router.push("/all-game?tab=all")
      }
    },
  },
  mounted() {
    this.initEnv()
    // 判断登录状态
    // this.notice() //通知
    this.getGameGetType();
    this.getIndexGameList();
    // this.getGameIndexOrderList();
    // this.getGameHighOrder();
    // 登录数据
    if (this.$store.state.isLoginStatu) {
      this.getUserGameOrder();
    }
  },
  created() {
    this.isPhone();
  },
  destroyed() {}
};
</script>
<style scoped lang="less">
.lobby__m-cells .el-select {
  margin-bottom: 10px;
}
/* all game */

.lobby__search {
  margin-bottom: 48px;
}

@media (max-width: 1000px) {
  .lobby__search {
    margin-bottom: 44px;
  }
}

@media (max-width: 1232px) {
  .opened-left-panel .lobby__search {
    margin-bottom: 44px;
  }
}

@media (max-width: 1325px) {
  .opened-right-panel .lobby__search {
    margin-bottom: 44px;
  }
}

@media (max-width: 1340px) {
  .opened-contests-panel .lobby__search {
    margin-bottom: 44px;
  }
}

@media (max-width: 1557px) {
  .opened-left-panel.opened-right-panel .lobby__search {
    margin-bottom: 44px;
  }
}

@media (max-width: 1572px) {
  .opened-left-panel.opened-contests-panel .lobby__search {
    margin-bottom: 44px;
  }
}

@media (max-width: 768px) {
  .lobby__search {
    margin-bottom: 40px;
  }
}

@media (max-width: 1000px) {
  .opened-left-panel .lobby__search {
    margin-bottom: 40px;
  }
}

@media (max-width: 1093px) {
  .opened-right-panel .lobby__search {
    margin-bottom: 40px;
  }
}

@media (max-width: 1108px) {
  .opened-contests-panel .lobby__search {
    margin-bottom: 40px;
  }
}

@media (max-width: 1325px) {
  .opened-left-panel.opened-right-panel .lobby__search {
    margin-bottom: 40px;
  }
}

@media (max-width: 1340px) {
  .opened-left-panel.opened-contests-panel .lobby__search {
    margin-bottom: 40px;
  }
}

.games {
  padding-bottom: 24px;
}

@media (max-width: 1000px) {
  .games {
    /* padding-bottom: 84px; */
  }
}

@media (max-width: 1232px) {
  .opened-left-panel .games {
    padding-bottom: 84px;
  }
}

@media (max-width: 1325px) {
  .opened-right-panel .games {
    padding-bottom: 84px;
  }
}

@media (max-width: 1340px) {
  .opened-contests-panel .games {
    padding-bottom: 84px;
  }
}

@media (max-width: 1557px) {
  .opened-left-panel.opened-right-panel .games {
    padding-bottom: 84px;
  }
}

@media (max-width: 1572px) {
  .opened-left-panel.opened-contests-panel .games {
    padding-bottom: 84px;
  }
}

.games__row {
  grid-template-columns: repeat(6, 1fr);
  display: grid;
  grid-gap: 24px;
}

@media (max-width: 800px) {
  .games__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1032px) {
  .opened-left-panel .games__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1125px) {
  .opened-right-panel .games__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1140px) {
  .opened-contests-panel .games__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1357px) {
  .opened-left-panel.opened-right-panel .games__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1372px) {
  .opened-left-panel.opened-contests-panel .games__row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 500px) {
  .games__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

@media (max-width: 732px) {
  .opened-left-panel .games__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

@media (max-width: 825px) {
  .opened-right-panel .games__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

@media (max-width: 840px) {
  .opened-contests-panel .games__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

@media (max-width: 1057px) {
  .opened-left-panel.opened-right-panel .games__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

@media (max-width: 1072px) {
  .opened-left-panel.opened-contests-panel .games__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}

.games__footer {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.tab__badge {
  color: #D7FFC4;
  margin-left: 8px;
  font-weight: 700;
  font-size: 10px;
  padding: 1px 6px;
  background: #fff;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .all-games {
    padding-bottom: 0px;
  }
}

.all-games.games {
  padding-bottom: 30px;
}

.game-preview {
  position: relative;
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
  background-color: #009A7A;
  display: block;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
}

.game-preview__loading-freespins {
  background-color: rgba(0, 154, 122, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.game-preview__loading-freespins h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
}

.game-preview__progress {
  width: 40px;
  height: 40px;
  position: relative;
}
@media(min-width:1024px){
	.game-preview.hover .game-preview__hover,
	.game-preview:hover .game-preview__hover {
	  opacity: 1;
	}

	.game-preview.hover .center,
	.game-preview:hover .center {
	  justify-content: center;
	}

	.game-preview.hover .game-preview__inner,
	.game-preview:hover .game-preview__inner {
	  filter: blur(3px);
	}

	.game-preview.hover .game-preview__image,
	.game-preview:hover .game-preview__image {
	  transform: scale(1.05);
	}

	.game-preview.hover .game-preview__name,
	.game-preview:hover .game-preview__name {
	  opacity: 1;
	  transform: scale(1);
	}
}
@media(max-width:1024px){
	.game-preview.hover .game-preview__hover,
	.game-preview:active .game-preview__hover {
	  opacity: 1;
	}

	.game-preview.hover .center,
	.game-preview:active .center {
	  justify-content: center;
	}

	.game-preview.hover .game-preview__inner,
	.game-preview:active .game-preview__inner {
	  filter: blur(3px);
	}

	.game-preview.hover .game-preview__image,
	.game-preview:active .game-preview__image {
	  transform: scale(1.05);
	}

	.game-preview.hover .game-preview__name,
	.game-preview:active .game-preview__name {
	  opacity: 1;
	  transform: scale(1);
	}
}

@media (max-width: 947px) {
  .game-preview.hover .game-preview__name,
  .game-preview:hover .game-preview__name {
    display: none;
  }
}

@media (max-width: 1179px) {
  .opened-left-panel .game-preview.hover .game-preview__name,
  .opened-left-panel .game-preview:hover .game-preview__name {
    display: none;
  }
}

@media (max-width: 1272px) {
  .opened-right-panel .game-preview.hover .game-preview__name,
  .opened-right-panel .game-preview:hover .game-preview__name {
    display: none;
  }
}

@media (max-width: 1287px) {
  .opened-contests-panel .game-preview.hover .game-preview__name,
  .opened-contests-panel .game-preview:hover .game-preview__name {
    display: none;
  }
}

@media (max-width: 1504px) {
  .opened-left-panel.opened-right-panel .game-preview.hover .game-preview__name,
  .opened-left-panel.opened-right-panel
    .game-preview:hover
    .game-preview__name {
    display: none;
  }
}

@media (max-width: 1519px) {
  .opened-left-panel.opened-contests-panel
    .game-preview.hover
    .game-preview__name,
  .opened-left-panel.opened-contests-panel
    .game-preview:hover
    .game-preview__name {
    display: none;
  }
}

.game-preview.hover .game-preview__btn-play,
.game-preview.hover .game-preview__lock,
.game-preview:hover .game-preview__btn-play,
.game-preview:hover .game-preview__lock {
  opacity: 1;
}

.game-preview.hover .game-preview__provider,
.game-preview:hover .game-preview__provider {
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 947px) {
  .game-preview.hover .game-preview__provider,
  .game-preview:hover .game-preview__provider {
    display: none;
  }
}

@media (max-width: 1179px) {
  .opened-left-panel .game-preview.hover .game-preview__provider,
  .opened-left-panel .game-preview:hover .game-preview__provider {
    display: none;
  }
}

@media (max-width: 1272px) {
  .opened-right-panel .game-preview.hover .game-preview__provider,
  .opened-right-panel .game-preview:hover .game-preview__provider {
    display: none;
  }
}

@media (max-width: 1287px) {
  .opened-contests-panel .game-preview.hover .game-preview__provider,
  .opened-contests-panel .game-preview:hover .game-preview__provider {
    display: none;
  }
}

@media (max-width: 1504px) {
  .opened-left-panel.opened-right-panel
    .game-preview.hover
    .game-preview__provider,
  .opened-left-panel.opened-right-panel
    .game-preview:hover
    .game-preview__provider {
    display: none;
  }
}

@media (max-width: 1519px) {
  .opened-left-panel.opened-contests-panel
    .game-preview.hover
    .game-preview__provider,
  .opened-left-panel.opened-contests-panel
    .game-preview:hover
    .game-preview__provider {
    display: none;
  }
}

.game-preview__shield {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 95px;
  height: 23px;
  background-size: 95px 23px;
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  transform: translateZ(1px);
}

.game-preview__shield_promo {
  background-image: url(../../assets/images/promo.png);
}

.game-preview__shield_popular {
  background-image: url(../../assets/images/popular.png);
}

.game-preview__shield_hot {
  /* background-image: url(../../assets/images/hot.png); */
}

.game-preview__shield_top {
  /* background-image: url(../../assets/images/top.png); */
}

.game-preview__shield_new {
  /* background-image: url(../../assets/images/new.png); */
}

.game-preview__shield_tournament {
  background-image: url(../../assets/images/tournament.png);
}

.game-preview__shield_battles {
  background-image: url(../../assets/images/battle.png);
}

.rtp .icon-preview svg {
  fill: #AED9CC;
}

.game-preview__inner {
  position: relative;
  overflow: hidden;
  padding-top: 133.34%;
}

.game-preview__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.1s ease-in-out;
}

.game-preview__image img {
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}
.game-preview__image .el-image {
  width: 100%;
  height: 100%;
}
.game-preview__hover {
  background-color: rgba(0, 154, 122, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  z-index: 1;
  padding: 19px 10px 13px;
  transition: opacity 0.1s ease-in-out;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  transform: translateZ(1px);
}

@media (max-width: 947px) {
  .game-preview__hover {
    justify-content: center;
  }
}

@media (max-width: 1179px) {
  .opened-left-panel .game-preview__hover {
    justify-content: center;
  }
}

@media (max-width: 1272px) {
  .opened-right-panel .game-preview__hover {
    justify-content: center;
  }
}

@media (max-width: 1287px) {
  .opened-contests-panel .game-preview__hover {
    justify-content: center;
  }
}

@media (max-width: 1504px) {
  .opened-left-panel.opened-right-panel .game-preview__hover {
    justify-content: center;
  }
}

@media (max-width: 1519px) {
  .opened-left-panel.opened-contests-panel .game-preview__hover {
    justify-content: center;
  }
}

.game-preview__name {
  opacity: 0;
  transform: scale(0.9);
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: center;
  max-width: 70%;
  display: -webkit-box;
  display: block;
  font-size: 14px;
  height: 36.4px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (max-width: 576px) {
  .game-preview__name {
    font-size: 12px;
    height: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 808px) {
  .opened-left-panel .game-preview__name {
    font-size: 12px;
    height: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 901px) {
  .opened-right-panel .game-preview__name {
    font-size: 12px;
    height: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 916px) {
  .opened-contests-panel .game-preview__name {
    font-size: 12px;
    height: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 1133px) {
  .opened-left-panel.opened-right-panel .game-preview__name {
    font-size: 12px;
    height: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
}

@media (max-width: 1148px) {
  .opened-left-panel.opened-contests-panel .game-preview__name {
    font-size: 12px;
    height: 20px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
}

.game-preview__provider {
  text-transform: capitalize;
  font-size: 11px;
  text-align: center;
  color: #AED9CC;
}

.game-preview__btn-play,
.game-preview__provider {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.game-preview__btn-play {
  width: calc(40% + 20px);
  min-width: 42px;
  background-color: #ed1d49;
  border-radius: 50%;
}

.game-preview__btn-play svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
  width: 20px;
  height: 20px;
}

@media (max-width: 576px) {
  .game-preview__btn-play svg {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 808px) {
  .opened-left-panel .game-preview__btn-play svg {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 901px) {
  .opened-right-panel .game-preview__btn-play svg {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 916px) {
  .opened-contests-panel .game-preview__btn-play svg {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 1133px) {
  .opened-left-panel.opened-right-panel .game-preview__btn-play svg {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 1148px) {
  .opened-left-panel.opened-contests-panel .game-preview__btn-play svg {
    width: 14px;
    height: 14px;
  }
}

.game-preview__btn-play:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.game-preview__btn-star {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  border: none;
  background-color: transparent;
}

.game-preview__btn-star svg {
  display: block;
  fill: #fff;
}

.spinner__bounce {
  background-color: #3EAB7E !important;
}

.button-tab {
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
  justify-content: flex-start;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
  border: 2px solid transparent;
  color: #AED9CC;
  background-color: transparent;
}

.button-tab_sm {
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 10px;
  border-radius: 3rem;
}

.button-tab:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button-tab.active,
.button-tab:active,
.button-tab:focus,
.button-tab:hover {
  color: #fff;
  background-color: transparent;
}

.button-tab.active,
.button-tab:disabled {
  cursor: default;
  background-color: #D7FFC4;
  opacity: 1;
}

.head-subcategories .button-tab {
  text-transform: capitalize;
}
.progress-wrapper {
  margin-top: 10px;
}

.category {
  content-visibility: auto;
  margin-bottom: 15px;
  @media (max-width:768px) {
    background-color: #004031;
    margin-left: -16px;
    margin-right: -16px;
    padding: 10px 16px;
    border-radius: 6px;
  }
}

.category__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 30px;
}

.category__tooltip {
  margin-left: 8px;
}

.category__tooltip,
.category__tooltip.icon,
.category__tooltip.icon svg {
  width: 20px;
  height: 20px;
}

.category__tooltip.icon svg:hover {
  fill: #AED9CC;
}

.category__title {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;
  color: #AED9CC;
  transition: color 0.1s ease-in-out;
}

.category__title img {
  margin-right: 5px;
  width: 22px;
}

.category__title:active,
.category__title:focus,
.category__title:hover {
  color: #fff;
}

.category__title:active svg,
.category__title:focus svg,
.category__title:hover svg {
  fill: #fff;
}

.category__head-buttons {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.category__head-buttons a {
  margin-right: 4px;
}

.category__head-buttons button:not(:first-child) {
  margin-left: 8px;
}
.category__head-buttons .button_md {
  min-width: 32px;
  height: 32px;
}
.category__head-buttons .button_md svg {
  width: 20px;
  height: 20px;
}
.category__body {
  position: relative;
}


.category__bonus-all {
  width: 100%;
  text-align: center;
}

.category__footer {
  padding-top: 32px;
  display: flex;
  justify-content: center;
}

.provider {
  background-color: #07503F;
  border-radius: 6px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
}

.provider,
.provider > img {
  display: block;
  width: 100%;
  overflow: hidden;
}
.provider-name{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #001E17;
  padding: 3px 0;
  font-size: 13px;
}
.provider-name img{
  width: 14px;
  margin-right: 3px;
}
.provider > img {
  all: unset;
  height: auto;
}

.provider:hover,.provider:active {
  background-color: #006852;
}
.provider:hover .logotype{
  transform: scale(1.1);
}
.provider .logotype{
  transition: all .3s;
}

.logotype {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 57px !important;
}
@media(max-width:750px){
	.logotype {
	  height: 40px !important;
	}
}

.statistics {
  margin-top: 48px;
}

.statistics__body {
  position: relative;
  min-height: 200px;
}

.statistics__inner {
  overflow-x: auto;
  overscroll-behavior-y: auto;
}

.statistics.panel {
  margin-top: 0;
}

.tab-badge {
  font-family: Roboto, serif;
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
  line-height: 1;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  background: #D7FFC4;
  margin: 0 -8px 0 8px;
  border-radius: 10px;
  padding: 4px 9px;
}

table.table-all td:first-child,
table.table-all th:first-child,
table.table-battles td:first-child,
table.table-battles th:first-child,
table.table-high td:first-child,
table.table-high th:first-child,
table.table-my td:first-child,
table.table-my th:first-child,
table.table-rare td:first-child,
table.table-rare th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}

.panel table.table-all td:first-child,
.panel table.table-all th:first-child,
.panel table.table-battles td:first-child,
.panel table.table-battles th:first-child,
.panel table.table-high td:first-child,
.panel table.table-high th:first-child,
.panel table.table-my td:first-child,
.panel table.table-my th:first-child,
.panel table.table-rare td:first-child,
.panel table.table-rare th:first-child,
table.table-all .panel td:first-child,
table.table-all .panel th:first-child,
table.table-battles .panel td:first-child,
table.table-battles .panel th:first-child,
table.table-high .panel td:first-child,
table.table-high .panel th:first-child,
table.table-my .panel td:first-child,
table.table-my .panel th:first-child,
table.table-rare .panel td:first-child,
table.table-rare .panel th:first-child {
  background-color: #009A7A;
}

table {
  width: 100%;
  font-weight: 600;
  font-size: 12px;
}

table td,
table th {
  padding: 8px;
  height: 56px;
  line-height: 1.8;
}

table td:first-child,
table th:first-child {
  padding-left: 16px;
}

table td:last-child,
table th:last-child {
  padding-right: 16px;
}

table th {
  color: #3EAB7E;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
}


table tbody tr {
  -webkit-animation-duration: var(--animation-duration);
  animation-duration: var(--animation-duration);
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform, opacity;
}

table tbody tr:first-child {
  -webkit-animation-name: horizont-1-data-v-47b53a9f;
  animation-name: horizont-1-data-v-47b53a9f;
}

table tbody tr:nth-child(2n) {
  -webkit-animation-name: horizont-2-data-v-47b53a9f;
  animation-name: horizont-2-data-v-47b53a9f;
}

table tbody tr:nth-child(odd) {
  -webkit-animation-name: horizont-3-data-v-47b53a9f;
  animation-name: horizont-3-data-v-47b53a9f;
}

table tbody td {
  position: relative;
  vertical-align: top;
  padding: 18px 8px;
}

table tbody td.align-middle {
  vertical-align: middle;
}

table tbody td:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #0F5745;
}

table.no-animation tbody tr {
  -webkit-animation: none;
  animation: none;
}

table.clickable tbody tr {
  cursor: pointer;
}

table.table-battles td:nth-child(3),
table.table-battles th:nth-child(3) {
  min-width: 165px;
}

table.table-battles td:nth-child(4),
table.table-battles th:nth-child(4) {
  width: 200px;
  min-width: 200px;
}

table.table-battles td:last-child,
table.table-battles th:last-child {
  width: 150px;
  min-width: 150px;
}

table.table-online td:first-child,
table.table-online td:nth-child(2),
table.table-online th:first-child,
table.table-online th:nth-child(2) {
  width: 50%;
  min-width: 50%;
}

table.table-online td:nth-child(3),
table.table-online th:nth-child(3) {
  width: 125px;
  min-width: 125px;
}

table.table-rounds td:nth-child(3),
table.table-rounds th:nth-child(3) {
  width: 500px;
  min-width: 500px;
}

table.table-all td:nth-child(4),
table.table-all th:nth-child(4) {
  min-width: 135px;
}

table.table-all td:nth-child(6),
table.table-all th:nth-child(6) {
  width: 125px;
  min-width: 125px;
}

table.table-my td:nth-child(4),
table.table-my th:nth-child(4) {
  min-width: 116px;
}

table.table-my td:nth-child(6),
table.table-my th:nth-child(6) {
  width: 125px;
  min-width: 125px;
}

table.table-high td:nth-child(4),
table.table-high th:nth-child(4),
table.table-rare td:nth-child(4),
table.table-rare th:nth-child(4) {
  min-width: 116px;
}

table.table-high td:nth-child(6),
table.table-high th:nth-child(6),
table.table-rare td:nth-child(6),
table.table-rare th:nth-child(6) {
  width: 125px;
  min-width: 125px;
}

@media screen and (min-width: 1000px) {
  @-webkit-keyframes horizont-1-data-v-47b53a9f {
    0% {
      opacity: 0;
      transform: translateY(-99.99%);
    }
  }

  @keyframes horizont-1-data-v-47b53a9f {
    0% {
      opacity: 0;
      transform: translateY(-99.99%);
    }
  }

  @-webkit-keyframes horizont-2-data-v-47b53a9f {
    0% {
      transform: translateY(-99.99%);
    }
  }

  @keyframes horizont-2-data-v-47b53a9f {
    0% {
      transform: translateY(-99.99%);
    }
  }

  @-webkit-keyframes horizont-3-data-v-47b53a9f {
    0% {
      transform: translateY(-99.99%);
    }
  }

  @keyframes horizont-3-data-v-47b53a9f {
    0% {
      transform: translateY(-99.99%);
    }
  }
}

.my-bet {
  background-color: #1c2532;
  border-radius: 14px;
}

.my-bet td:first-child {
  background-color: #1c2532 !important;
}

.user {
  display: flex;
  align-items: center;
}

.user--clickable {
  cursor: pointer;
}

.user__avatar {
  position: relative;
  flex-shrink: 0;
}

.user__inner {
  right: 0;
  overflow: hidden;
  border-radius: 50%;
}

.user__inner,
.user__inner img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.user__inner img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  display: block;
  width: auto;
  height: 100%;
  max-width: none;
}

.user__level {
  right: -4px;
  bottom: -4px;
  position: absolute;
  background-color: #3EAB7E;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  border-radius: 50%;
  color: #fff;
  pointer-events: none;
}

.user__additional-info,
.user__name {
  margin-left: 8px;
}

.user__wrap {
  display: flex;
  flex-direction: column;
}

.user__additional-info {
  letter-spacing: -0.02em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #AED9CC;
}


@-webkit-keyframes confetti-down-data-v-40468d4e {
  0% {
    top: -80%;
  }

  to {
    top: 130%;
  }
}

@keyframes confetti-down-data-v-40468d4e {
  0% {
    top: -80%;
  }

  to {
    top: 130%;
  }
}

@-webkit-keyframes pulse-data-v-40468d4e {
  0% {
    transform: translate(-50%) scale(1);
  }

  50% {
    transform: translate(-50%) scale(1.2);
  }

  to {
    transform: translate(-50%) scale(1);
  }
}

@keyframes pulse-data-v-40468d4e {
  0% {
    transform: translate(-50%) scale(1);
  }

  50% {
    transform: translate(-50%) scale(1.2);
  }

  to {
    transform: translate(-50%) scale(1);
  }
}
.wink-boost {
  position: relative !important;
  top: auto !important;
  bottom: auto !important;
  width: 190px;
  z-index: 5;
  margin-top: 8px;
  filter: drop-shadow(4px 4px 30px rgba(0, 0, 0, 0.35));
}
.intro__inner {
  margin-top: 20px;
  z-index: 1;
  max-width: 550px;
}
.intro picture {
  position: absolute;
  max-width: 710px;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.td-time,
.td-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 768px) {
  .intro picture {
    right: 50%;
    transform: translate(50%);
    width: 100%;
    top: auto;
    height: auto;
    bottom: 0px;
  }
  .td-name {
    max-width: 120px;
  }
}

.m-search {
  position: relative;
  margin-top: 15px;
}
.view-all {
  display: block;
  height: 100%;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: linear-gradient(180deg, rgba(34, 131, 246, 0), #D7FFC4);
}
.view-all__hover {
  background-image: url(../../assets/images/view-all.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.1s ease-in-out;
}
.view-all__label {
  color: #fff;
  font-size: 18px;
}
.view-all:focus,
.view-all:hover {
  text-decoration: none;
}
.view-all:focus .view-all__hover,
.view-all:hover .view-all__hover {
  transform: scale(1.05);
}
.view-all__label {
  color: #fff;
  font-size: 18px;
}
.td-currency {
  min-width: max-content;
}
.turntable{
  width: 250px;
}
.intro{
  justify-content: space-between;
  padding: 0 60px;
}
@media (max-width: 768px) {
  .intro{
    flex-direction: column-reverse;
    padding: 0;
  }
  .turntable{
    width: 100%;
  }
}

.button_gre{
    background-image: linear-gradient(to bottom,#da3f3d,#70191b);
    color: #fff;
    height: 120%;
    text-align: center;
    min-width: 120px;
    border-color: transparent;
    font-size:20px;
}
.liwu-icon{
  height: 100%;
  border-radius: 10px;
}
.intro__actions_txt{
  flex: 1;
  margin: 0 12px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
}
.blue{
  color: #5e718d;
}
.intro__title{
	max-width:400px;
}
@media(max-width:750px){
	.intro__actions .button_gre{
		min-width: 80px;
		height: 114%;
		font-size:16px;
		padding:0;
	}
	.intro__actions{
		height:60px;
	}
	.intro__actions_txt{
		line-height:1.2;
		-webkit-line-clamp: 3; /* 这里是超出几行省略 */
	}
	.liwu-icon{
		width:36px;
		height:36px;
	}
}

/* 活动轮播 */
.info-turntable{
	position: relative;
}

.b-pics{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.b-pic{
  width: 32%;
  border-radius: 10px;
  height: 210px;
}
@media(max-width:750px){
  .b-pic{
    width: 100%;
  }
	.info-turntable{
		width: 100%
	}
  .b-pics .b-pic:nth-child(2),
  .b-pics .b-pic:nth-child(3){
    display: none;
  }
}
.game-main{
  min-height: 100vh!important;
}
::-webkit-scrollbar {
   height: 0;
}


@keyframes trans {
  0%,
  50%,
  to {
    border: 2px solid #ff0000;
  }
  25%,
  75% {
    border: 2px solid#0084ff;
  }
}

@keyframes trans2 {
  0%{
    transform: rotate(-45deg) translate(0, -18px)
  }
  100% {
    transform: rotate(-45deg) translate(0, 18px)

  }
}


.tab {
  position: relative;
  font-weight: 500;
  font-size: 13px;
  color: #AED9CC;
  text-decoration: none;
  background-color: transparent;
  border-radius: 7px;
  margin-right: 6px;
  padding: 6px 15px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
}

@media (min-width:768px) {
  .tab {
    display: flex;
    align-items: center;
  }
}

.tab:hover {
  color: #fff;
}

.tab__icon {
  // width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  position: relative;
}

@media (min-width:768px) {
  .tab__icon {
    margin-right: 10px;
  }
}

.tab__icon img {
  display: block;
  width: auto;
  height: 100%;
}

.tab__title {
  white-space: nowrap;
  display: block;
}

.tab__badge {
  color: #D7FFC4;
  margin-left: 8px;
  font-weight: 700;
  font-size: 10px;
  padding: 1px 6px;
  background: #fff;
  border-radius: 4px;
}

.tab.active {
  background-color: #FFD700;
  color: #917800;
}

.tab-item {
  display: flex;
  align-items: center;
}

.tab-item i {
  height: 1.5em;
  width: 1.5em;
}

.tab-item img {
  margin-right: 0.5em;
  width: 70%;
  height: 70%;
}
.lobby-tabs {
  background: #00120E;
  border-radius: 6px;
  padding: 8px;
  width: max-content;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}


.merry-GoRound-box{
  @media (max-width:768px) {
    padding: 10px 16px;
    margin: 0 -16px;
    border-radius: 6px;
    background-color: #004031;
    margin-bottom: 15px;
    ::v-deep .m-wapper{
      margin-bottom: 0;
    }
  }
}
.banner-box{
  @media (max-width:768px) {
    padding: 15px 16px;
    margin: -15px -16px 0;
    border-radius: 6px;
    background-color: #004031;
    margin-bottom: 15px;
    ::v-deep .banners{
      margin: 0 !important;
    }
  }
}
.lobby__tabs-box{
  @media (max-width:768px) {
    padding: 15px 16px;
    margin: 0 -16px 0;
    border-radius: 6px;
    background-color: #004031;
    margin-bottom: 15px;
    .lobby__tabs{
      margin-bottom: 0;
    }
  }
}
.category__title_button{
  @media (max-width:768px) {
    margin-top: -6px;
  }
}
.title-button{
  @media (max-width:768px) {
    min-width: 96px;
    height: 38px;
    background-image: url(~@/assets/images/home-bg-btn.png);
    background-size: 100% 100%;
    background-color: transparent;
    padding-left: 20px;
    transform: translate(15px,-4px);
    .button__inner{
      transform: translateY(-3px);
      .button__text{
        font-size: 11px;
      }
    }
  }
}
.tab-hot {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 0%);
  width: 16px;
  height: 16px;
  border: 2px solid #ff0000;

  border-radius: 50%;
  animation: 2s trans infinite;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: #01ff79;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>
