<template>
  <el-dialog :modal-append-to-body="false" :visible.sync="isShow" v-if="list.length&&$route.query.modal!='LoginRegistration'&&$route.query.pdd!='show'" :show-close="false"
    :close-on-click-modal="false" custom-class="custom-dialog my-dialog">
    <div class="close">
      <i class="el-icon el-icon-error" @click="hide(true)"></i>
    </div>
    <div class="dialog-wapper">
      <swiper class="turntable-thumbs" :options="turntableThumbs"  ref="swiperThumbs">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div class="pd-wapper">
            <div class="html" v-html="list[index].content"></div>
            <div class="bottom-btn-wapper">
              <el-button class="button_gre" @click="toPage(item.jump_url)">{{ $t("了解") }}</el-button>
              <div class="tip-txt" @click="hide(true)">{{ $t('今天不要提醒我') }}</div>
            </div>
            <div class="slider-wapper">
              <div :class="['slider-item', cur == index ? 'item-ac' : '']" v-for="item, cur in list" :key="cur"></div>
            </div>
          </div>

        </swiper-slide>

      </swiper>
      <!-- <div class="splide__arrow--next-notice">
        <img :src="require('@/assets/images/kno_next.png')">
      </div>
      <div class="splide__arrow--prev-notice">
        <img :src="require('@/assets/images/kno_next.png')">
      </div> -->
    </div>


  </el-dialog>
</template>

<script>
import { notice_list_api } from "@/api";
export default {
  data() {
    return {
      isShow: false,
      list: [],
      cur: 0,
      checked: false,
      flag: false,
      turntableThumbs: {
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        // navigation: {
        //   nextEl: ".splide__arrow--next-notice",
        //   prevEl: ".splide__arrow--prev-notice"
        // },
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
    };
  },
  mounted() {
    this.show();
  },
  watch: {
    "$store.state.isLoginStatu": function (v) {
      if (v) {
        this.hide(true);
      }else{
        this.hide(false);
      }
    },
  },
  methods: {
    toPage(url) {
      this.hide(true)
      if (url.startsWith('http')) {
        location.href = url
      } else {
        this.$router.push(url)
      }
    },
    hide(isDay = false) {
      const key = "noticeHideTime";
      if (isDay) {
        const date = new Date();
        const time = `${date.getFullYear()}/${date.getMonth() +
          1}/${date.getDate()}`;
        localStorage.setItem(key, time);
      }else{
        localStorage.setItem(key, null);
      }
      this.isShow = false;
    },
    async show() {
      const key = "noticeHideTime";
      const date = new Date();
      const time = `${date.getFullYear()}/${date.getMonth() +
        1}/${date.getDate()}`;
      // 判断时间相等不显示，不相等进行显示
      if (time == localStorage.getItem(key)) return;

      await this.getData();

      this.isShow = true;
    },
    async getData() {
      if (this.flag) return;
      this.flag = true;
      const res = await notice_list_api({
        page: 1,
        limit: 100,
        type: 2,
        show_loca: 1,
      });
      if (res.data.code == 1) {
        this.list = res.data.data;
        console.log(this.list)
      }
    },
    next() {
      if (this.list.length - 1 == this.cur) return;
      this.cur++;
    },
    pre() {
      if (this.list.length == 0) return;
      this.cur--;
    },
  }
};
</script>

<style lang="less" scoped>
.custom-dialog {
  width: 100% !important;
}

.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4px;
  margin-top: 20px;
}

.slider-item {
  height: 100%;
  width: 10px;
  background-color: #3EAB7E;
  border-radius: 100vh;
  margin-right: 6px;
}

.item-ac {
  width: 20px;
  background-color: #0BDA45;

}

.slider-item:last-child {
  margin-right: 0;
}

.dialog-wapper {
  border-radius: 20px;
  overflow: hidden;
  padding: 20px 0;
}

.pd-wapper {
  padding: 0 20px;
}

.title-txt {
  color: #fff;
  font-size: 14px;
}

::v-deep .el-checkbox__inner {
  background-color: #a1a1a1 !important;
  border-color: #a1a1a1 !important;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0075ff !important;
  border-color: #0075ff !important;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  background: transparent;
  padding: 0;
}

::v-deep .el-dialog {
  background-color: transparent;
  box-shadow: none;
  overflow: visible;
}

@media (max-width: 768px) {
  ::v-deep .el-dialog {
    /* padding: 20px; */
  }
}

.title-wapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns-wapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.el-icon-arrow-right {
  margin-left: 6px;
}

.el-icon-arrow-left {
  margin-right: 6px;
}

.bottom-btn-wapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

.hide {
  visibility: hidden;
}

.button_gre {
  width: 100%;
  max-width: 366px;
  background-color: #ac0000;
  font-weight: bold;
  border-radius: 100vh;
}

@media (max-width:540px) {
  .button_gre {
    max-width: 90%;
  }
}

.close {
  /* margin-left: auto; */
  display: block;
  font-size: 32px !important;
  /* text-align: right; */
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(25%, -50%);
  z-index: 9999;
  color: #fff;
  /* transform: translateY(30px); */

}

.close i {
  cursor: pointer;
}

@media (max-width: 768px) {
  .close {
    font-size: 20px;
    /* transform: translateX(20px); */
  }
}

.tip-txt {
  color: #AED9CC;
  font-size: 12px;
  margin-top: 12px;
  cursor: pointer;
}

.html {
  height: 60vh;
  overflow: auto;
}

@media (max-width:768px) {
  .html {
    height: 50vh;
    overflow: auto;
  }
}

.splide__arrow--next-notice,
.splide__arrow--prev-notice {
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  z-index: 9999;
  cursor: pointer;
  --bj: 10px;
  --size: 40px;
}

@media (max-width:768px) {

  .splide__arrow--next-notice,
  .splide__arrow--prev-notice {
    --bj: 25px;
    --size: 30px;
  }

  /deep/.el-pagination.is-background .el-pager li {
    margin: 0 5px;
    color: #3EAB7E;
    background-color: rgba(84, 92, 108, .25);
    min-width: 26px !important;
    border-radius: 5px !important;
  }

}


.splide__arrow--next-notice img,
.splide__arrow--prev-notice img {
  width: 100%;
  height: 100%;
}

.splide__arrow--next-notice {
  right: var(--bj);
}

.splide__arrow--prev-notice {
  left: var(--bj);
  transform: translateY(-60%) rotate(180deg);
}

.pagination {
  padding-top: 10px;
  text-align: center;
}
</style>
