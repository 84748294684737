<template>
  <div v-if="$t('egg_is_show')!='hide'">
    <div class="egg-box" @click="show" ref="egg">
      <img src="@/assets/images/egg.png" class="egg">
      <img src="@/assets/images/egg-bg.png" class="egg-bg">
    </div>
    <div class="pop" :style="`--offsetX:${offsetX}px;--offsetY:${offsetY}px`" @click="hide">
      <transition name="show">
        <div class="pop-content" v-show="$store.state.egg.eggPopIsShow">
          <img src="@/assets/images/egg-pop.png" class="egg-pop-img">
          <i class="el-icon el-icon-circle-close"></i>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div class="pop-mask" v-if="$store.state.egg.eggPopIsShow"></div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      isShow:true,
      offsetX:0,
      offsetY:0,
    }
  },
  mounted(){
    console.log(this.$store.state.egg)
  },
  methods:{
    hide(){
      this.offsetX = this.$refs.egg.offsetLeft + (this.$refs.egg.offsetWidth/2)
      this.offsetY = this.$refs.egg.offsetTop + (this.$refs.egg.offsetHeight/2)
      this.$nextTick(()=>{
        this.isShow = false
        this.$store.commit('egg/changeEggPop',false);
      })
    },
    show(){
      this.offsetX = this.$refs.egg.offsetLeft + (this.$refs.egg.offsetWidth/2)
      this.offsetY = this.$refs.egg.offsetTop + (this.$refs.egg.offsetHeight/2)
      this.$nextTick(()=>{
        this.$store.commit('egg/changeEggPop',true);
      })
    }
  }
}
</script>

<style scoped lang="less">
.egg-box{
  position: fixed;
  width: 45px;
  z-index: 9;
  bottom: 110px;
  right: 26px;
  cursor: pointer;
  user-select: none;
  @media (min-width:769px) {
    right: 36px;
    bottom: 100px;
  }
}
.egg{
  width: 100%;
  display: block;
  position: relative;
  z-index: 2;
}
.egg-bg{
  position: absolute;
  top: 0;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
  max-width: 400%;
  animation: rotate 3s infinite linear;
}
@keyframes rotate {
  0%{
    transform: translateX(-50%) rotate(0deg);
  }100%{
    transform: translateX(-50%) rotate(360deg);
  }
}


.pop{
  user-select: none;
}
.pop-content{
  position: fixed;
  z-index: 2002;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  transform-origin: 0 0;
}
.pop-mask{
  position: fixed;
  background-color: rgba(0,0,0,.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2001;
}
.pop-content{
  width: 100vw;
  max-width: 420px;
  text-align: center;
  @media (min-width:769px) {
    max-width: 620px;
  }
}
.egg-pop-img{
  width: 100vw;
}
.el-icon-circle-close{
  font-size: 24px;
  margin-top: 20px;
  cursor: pointer;
  @media (min-width:769px) {
    font-size: 30px;
  }
}


.show-enter-active, .show-leave-active {
  transition: all 0.5s;
}
.show-enter{
  left: var(--offsetX);
  top: var(--offsetY);
  transform: translate(0,0) scale(0) rotate(-20deg);
} 
.show-leave-to {
  left: var(--offsetX);
  top: var(--offsetY);
  transform: translate(0,0) scale(0) rotate(-20deg);
}

</style>