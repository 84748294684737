<template>
    <div v-if="list.length">
        <div class="m-wapper">
            <img src="@/assets/images/p-icon.png" alt="" class="m-icon">
            <div class="content-wapper" ref="box">
                <div class="content-txt" ref="contentTxt"
                    :style="`--d:${time}s;--cs:${list.length == 1 ? 'infinite' : '1'}`"
                    v-if="active == index" v-for="item, index in list" :key="index" @animationend="animationend">
                    <div v-html="item.content"></div>
                </div>
            </div>
            <div @click="toPage(list[active].jump_url)" v-if="list[active].jump_url">
                <el-button class="button_blue btn" size="mini">{{ $t("了解更多") }}</el-button>
            </div>
        </div>
    </div>
    
</template>

<script>
import { notice_list_api } from "@/api";
export default {
    data(){
        return {
            list:[],
            active:0,
            flag:true,
            time:4,
        }
    },
    async mounted(){
        await this.getData()
    },
    methods:{
        initTime(){
            this.$nextTick(()=>{
                if(this.$refs.contentTxt){
                    this.time = this.$refs.contentTxt[0].offsetWidth / this.$refs.box.offsetWidth * (this.$refs.box.offsetWidth/100)
                }
            })
        },
        toPage(url){
            if(url.startsWith('http')){
				location.href = url
			}else{
				this.$router.push(url)
			}
        },
        async getData(){
            const {data} = await notice_list_api({
                type:1,
                show_loca:1
            })
            if(data.code==1){
                this.list = data.data.sort(() => Math.random() - 0.5);
                this.initTime()
            }
        },
        animationend(){
            this.active ++
            if(this.active>=this.list.length){
                this.active=0
            }
            this.flag = false
            this.initTime()
        }
    }
}
</script>

<style scoped lang="less">
.m-wapper {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 4px 0 18px;
    margin-bottom: 18px;
    background-color: #001E17;
    border-radius: 5px;
    color: #3D7C69;

    @media (max-width:768px) {
        height: 40px;
        padding: 0 4px 0 10px;
        margin-bottom: 20px;
    }
}

.m-icon {
    width: 24px;
    height: 24px;

    @media (max-width:768px) {
        width: 20px;
        height: 20px;
    }
}

.btn {
    background: linear-gradient( 180deg, #FFD700 0%, #B29600 100%);
    min-width: 85px;
    color: #917800;
    @media (max-width:768px) {
        min-width: 0;
    }
}

.content-wapper {
    flex: 1;
    margin: 0 20px;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width:768px) {
        font-size: 14px;
        margin: 0 10px;
    }
}

.content-txt {
    --d: 4s;
    --cs: infinite;
    --left: 1040px;
    animation: var(--d) an var(--cs) linear;
    display: inline-block;
    min-width: 100%;

    @media (max-width :1040px) {
        --left: 100vw;
    }
}

@keyframes an {
    from {
        transform: translateX(var(--left));
    }

    to {
        transform: translateX(-100%);
    }
}
</style>